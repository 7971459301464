<template>
	<div>
		<table id="techniques" class="mobile-table table-sticky">
			<thead>
				<tr>
					<th id="title-name" rowspan="2">{{$t("common.name")}}</th>
			<!--		<th rowspan="2">{{$t("common.hair")}}</th> -->
					<th :colspan="$store.getters.salonAcceptsChildren() ? 3 : 2">{{$t("common.price")}}</th>
					<th rowspan="2">{{$t("common.action")}}</th>
				</tr>
				<tr>
					<th colspan="1">{{$t("common.wom")}}</th>
					<th colspan="1">{{$t("common.men")}}</th>
					<th colspan="1" rowspan="1" v-if="$store.getters.salonAcceptsChildren()">{{$t("common.youth")}}</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="service in services"
					:key="service.id"
					:class="{ 'row-border-top': true, 'active': activeServices.indexOf(service.id) >= 0 }"
					@click="toggleServiceActive(service)"
				>
					<td class="max-width-1 table-name-mobile" rowspan="1">{{ service.title }}</td>
			<!--		<td class="hair"></td> -->
					<table class="mobile">
						<thead>
							<tr>
								<th :colspan="!!service.price_female + !!service.price_male + !!service.price_child" v-if="service.price_female || service.price_male || service.price_child">
									{{ $t("common.price") }}
								</th>
							</tr>
							<tr>
								<th colspan="1" v-if="service.price_female">
									{{ $t("common.wom") }}
								</th>
								<th colspan="1" v-if="service.price_male">
									{{ $t("common.men") }}
								</th>
								<th colspan="1" rowspan="1" v-if="service.price_child">
									{{ $t("common.youth") }}
								</th>
							</tr>
						</thead>
						<tbody>
							<tr :key="service.id + '-1'" class="row-border-top">
								<td colspan="3" v-if="service.only_quote" class="text-center">{{ $t("modals.editservice.onlyquote") }}</td>
								<template v-else>
									<td v-if="service.price_female" class="td-small cell-f mobile-33" :rowspan="service.rowspan">
										{{ service.price_female }}
									</td>
									<td v-if="service.price_male" class="td-small cell-h mobile-33" :rowspan="service.rowspan">
										{{ service.price_male }}
									</td>
									<td v-if="service.price_child" class="td-small cell-enf mobile-33" :rowspan="service.rowspan">
										{{ service.price_child }}
									</td>
								</template>
							</tr>
						</tbody>
					</table>
					<td :colspan="$store.getters.salonAcceptsChildren() ? 3 : 2" v-if="service.only_quote" class="text-center text-muted desktop">{{ $t("modals.editservice.onlyquote") }}</td>
					<template v-else>
						<td class="td-small cell-f desktop" rowspan="1">{{ service.price_female }}</td>
						<td class="td-small cell-h desktop" rowspan="1">{{ service.price_male }}</td>
						<td class="td-small cell-enf desktop" rowspan="1" v-if="$store.getters.salonAcceptsChildren()">{{ service.price_child }}</td>
					</template>
					<td class="td-button mobile-100" rowspan="1">
						<button class="btn-mod fas fa-edit btn btn-outline-success" v-on:click="currentlyEditing = service; showEditModal = true" type="button"/>
					</td>
				</tr>
				<tr id="btn-add2">
					<td class="td-button add-presta mobile-100" colspan="12">
						<button
							id="add-presta"
							class="btn-add fas fa-plus btn btn-tertiary"
							type="button"
							@click="currentlyEditing = null; showEditModal = true"
						/>
					</td>
				</tr>
			</tbody>
		</table>
		<EditService v-if="showEditModal" :title="$t('data.servicestype[-1]')" :technic="true" :prefillData="currentlyEditing" @close="showEditModal = false"/>
	</div>
</template>

<script>
	import EditService from '../../modals/EditService.vue'

	export default {
		components: {
			EditService
		},
		data() {
			return {
				showEditModal: false,
				currentlyEditing: null,
				activeServices: [],
			}
		},
		methods: {
			toggleServiceActive({ id }) {
				const index = this.activeServices.indexOf(id)
				if (index >= 0)
					this.activeServices.splice(index)
				else
					this.activeServices.push(id)
			}
		},
		computed: {
			services() {
				var services = this.$store.getters.getServices();
				services = services.sort( ( a, b ) => a.id - b.id );
				for ( var i = 0; i < services.length; i++ ) {
					var service = services[i];
					// only keep special types
					if ( service.type >= 0 ) {
						services.splice( i--, 1 );
						continue;
					}
					service.price_female = (service.female_short||[])[0] || (service.female_half||[])[0] || (service.female_long||[])[0];
					service.price_male = (service.male_short||[])[0] || (service.male_half||[])[0] || (service.male_long||[])[0];
					service.price_child = (service.child||[])[0];
					if ( service.price_female ) {
						service.price_female += "€";
					}
					if ( service.price_male ) {
						service.price_male += "€";
					}
					if ( service.price_child ) {
						service.price_child += "€";
					}
				}
				return services;
			}
		}
	}
</script>
